import {makeStaticStyles} from '@fluentui/react-components';

// Lubalin font
//
// It is available in a zip file on Galaxis and also comes pre-installed under
// /usr/share/fonts/AXIS, the name of this font is "Lubalin Graph ECG".
// The `local` source should attempt to use this first.
//
// There is a "Book" and a "Demi" version in OTF format, use `fc-scan` to get font weights:
// Book: 80 (400 in CSS, or normal)
// Demi: 180 (600 in CSS, or semi-bold)
//
// Install (apt) package `woff2` to compress/extract woff2 files, this saves some space.
// There is also a TTF version online which is significantly smaller but might not be
// compatible with our license, and could be different since Galaxis mentions an old version.
export const useGlobalStaticStyle = makeStaticStyles([
  {
    '*': {
      boxSizing: 'border-box',
      /**
       * Unset "thin" from useScrollStaticStyles to prevent having default scrollbar in Electron.
       * This fixes scrollbar style in Electron but it makes the scrollbar in Firefox thicker.
       * Normally this style is only supported by Firefox and not by Chrome. But it is enabled in Electron
       * because we enables 'enable-experimental-web-platform-features'.
       */
      scrollbarWidth: 'unset'
    },
    body: {
      margin: 0,
      padding: 0,
      overflowX: 'hidden'
    }
  },
  {
    '@font-face': {
      fontFamily: 'Lubalin',
      fontStyle: 'normal',
      fontWeight: '400',
      // @ts-expect-error: font-display is missing from the types but works
      fontDisplay: 'block',
      src: 'local("Lubalin Graph ECG"), url("/LubalinGraphECG-Book.woff2") format("woff2")'
    }
  },
  {
    '@font-face': {
      fontFamily: 'Lubalin',
      fontStyle: 'normal',
      fontWeight: '600',
      fontDisplay: 'block',
      src: 'local("Lubalin Graph ECG"), url("/LubalinGraphECG-Demi.woff2") format("woff2")'
    }
  }
]);
