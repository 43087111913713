import Loader from '../views/Loader';
import {EmptyView} from './EmptyView';

interface NoValidOrganizationProps {
  loaded: boolean;
}
const NoValidOrganization = ({loaded}: NoValidOrganizationProps) => {
  if (!loaded) {
    return <Loader />;
  }
  return <EmptyView.EmptySpace title="No organization selected" />;
};

export default NoValidOrganization;
