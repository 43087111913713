import {
  makeStyles,
  TagPicker,
  TagPickerList,
  TagPickerOption,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  Tag
} from '@fluentui/react-components';

interface ScenarioTagPickerProps {
  options: {name: string}[];
  selectedOptions: string[];
  onUnassign: (tagName: string) => void;
  onAssign: (tagName: string) => void;
  scenarioId: number;
}

const useStyles = makeStyles({
  fullWidth: {
    width: '100%'
  }
});

export const ScenarioTagPicker = ({
  options,
  onAssign,
  onUnassign,
  selectedOptions,
  scenarioId
}: ScenarioTagPickerProps) => {
  const styles = useStyles();

  return (
    <TagPicker
      onOptionSelect={(_, data) => {
        if (data.value === 'no-options') return;
        selectedOptions.includes(data.value) ? onUnassign(data.value) : onAssign(data.value);
      }}
      selectedOptions={selectedOptions}
    >
      <TagPickerControl className={styles.fullWidth} data-testid={'tag-picker-' + scenarioId}>
        <TagPickerGroup>
          {selectedOptions.map(option => (
            <Tag
              key={'tag-picker-selected-' + option}
              value={option}
              data-testid={'tag-picker-selected-' + option}
            >
              {option}
            </Tag>
          ))}
        </TagPickerGroup>
        <TagPickerInput />
      </TagPickerControl>
      <TagPickerList>
        {options.length > 0 ? (
          options.map(option => (
            <TagPickerOption
              value={option.name}
              key={'tag-picker-option-' + option.name}
              data-testid={'tag-picker-option-' + option.name}
            >
              {option.name}
            </TagPickerOption>
          ))
        ) : (
          <TagPickerOption value="no-options">No options available</TagPickerOption>
        )}
      </TagPickerList>
    </TagPicker>
  );
};
