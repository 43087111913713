import {
  Badge,
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  makeStyles,
  SkeletonItem,
  TableCellLayout,
  TableColumnDefinition,
  Text,
  tokens,
  Tooltip
} from '@fluentui/react-components';
import {DeviceInfo, DeviceStatus} from '../../types';
import {DeviceModelImage} from '../../components/DeviceModelImage';
import {EmptyView} from '../../components/EmptyView';

const useStyles = makeStyles({
  table: {
    width: '100%',
    display: 'flex',
    paddingTop: tokens.spacingVerticalXL,
    paddingRight: tokens.spacingHorizontalXXL,
    paddingBottom: tokens.spacingVerticalS,
    flexDirection: 'column',
    overflowY: 'auto',
    height: '100%',
    scrollbarWidth: 'thin'
  },
  row: {
    cursor: 'pointer'
  },
  cameraModel: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalM
  },
  cameraNameModel: {
    display: 'flex',
    justifyContent: 'center',
    rowGap: tokens.spacingVerticalXXS,
    flexDirection: 'column'
  },
  cameraModelText: {
    color: tokens.colorNeutralForeground3
  },
  badgeSkeleton: {
    width: '80px'
  }
});

interface DeviceTableProps {
  readonly devices: DeviceInfo[];
  readonly onDeviceSelected: (serial: string) => void;
}

const getBadge = (status: DeviceStatus, styles: Record<string, string>) => {
  switch (status) {
    case DeviceStatus.Unknown:
      return <SkeletonItem className={styles.badgeSkeleton} data-testid="loading-status" />;
    case DeviceStatus.Reachable:
      return (
        <Tooltip content="Device can be configured to send data" relationship="label">
          <Badge appearance="tint" color="informative" shape="rounded">
            Reachable
          </Badge>
        </Tooltip>
      );
    case DeviceStatus.Connected:
      return (
        <Tooltip content="Device is sending data to Data Insights Dashboard" relationship="label">
          <Badge appearance="tint" color="success" shape="rounded">
            Sending data
          </Badge>
        </Tooltip>
      );
    case DeviceStatus.Unreachable:
      return (
        <Tooltip content="Unable to connect to device" relationship="label">
          <Badge appearance="tint" color="danger" shape="rounded">
            Unreachable
          </Badge>
        </Tooltip>
      );
    case DeviceStatus.Forbidden:
      return (
        <Tooltip content="Not allowed to configure device" relationship="label">
          <Badge appearance="tint" color="important" shape="rounded">
            Access denied
          </Badge>
        </Tooltip>
      );
  }
};

const getStatus = (device: DeviceInfo): DeviceStatus => {
  if (device.status === undefined) {
    return DeviceStatus.Unknown;
  } else {
    return device.status;
  }
};

export const DeviceTable = ({devices, onDeviceSelected}: DeviceTableProps) => {
  const styles = useStyles();

  const columns: TableColumnDefinition<DeviceInfo>[] = [
    createTableColumn<DeviceInfo>({
      renderHeaderCell: () => 'Camera model / name',
      renderCell: item => (
        <TableCellLayout>
          <div className={styles.cameraModel}>
            <DeviceModelImage piaId={item.piaItemId} />
            <div className={styles.cameraNameModel}>
              <Text weight="semibold">{item.name ? item.name : item.model}</Text>
              <Text className={styles.cameraModelText}>{item.name ? item.model : undefined}</Text>
            </div>
          </div>
        </TableCellLayout>
      ),
      columnId: 'model',
      compare: (a, b) => (a.model || '').localeCompare(b.model || '')
    }),
    createTableColumn<DeviceInfo>({
      renderHeaderCell: () => 'Status',
      renderCell: item => getBadge(getStatus(item), styles),
      compare: (a, b) => getStatus(a) - getStatus(b),
      columnId: 'connected'
    }),
    createTableColumn<DeviceInfo>({
      renderHeaderCell: () => 'Serial',
      renderCell: item => <TableCellLayout>{item.serial}</TableCellLayout>,
      columnId: 'serial',
      compare: (a, b) => a.serial.localeCompare(b.serial)
    })
  ];

  if (devices.length === 0) {
    return <EmptyView.EmptyFolder title="There are no devices" />;
  }

  return (
    <DataGrid
      className={styles.table}
      items={devices}
      columns={columns}
      data-testid="devices-table"
      sortable
      defaultSortState={{sortColumn: 'serial', sortDirection: 'descending'}}
    >
      <DataGridHeader>
        <DataGridRow>
          {({renderHeaderCell}) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<DeviceInfo>>
        {({item, rowId}) => (
          <DataGridRow<DeviceInfo>
            data-testid={item.serial + '-device-table-row'}
            key={rowId}
            onClick={() => {
              onDeviceSelected(item.serial);
            }}
            className={styles.row}
          >
            {({renderCell}) => <DataGridCell>{renderCell(item)}</DataGridCell>}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  );
};
