import {Button, makeStyles, Spinner, tokens} from '@fluentui/react-components';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import PageHeader from '../../components/PageHeader';
import {useAppSelector} from '../../store/hooks';
import {getBasicDeviceInfo, getDeviceInfos} from '../../fetcher';
import {useEffect, useState} from 'react';
import {DeviceInfo, DeviceStatus} from '../../types';
import {useOpenTelemetry} from '@axteams-one/opentelemetry-js-react';
import {DeviceTable} from './DevicesTable';
import DataSourcePanel from './DataSourcePanel';
import PageContent from '../../components/PageContent';
import NoValidOrganization from '../../components/NoValidOrganization';
import {useSearchParams} from 'react-router-dom';
import useToasts from '../../hooks/useToasts';

const useStyles = makeStyles({
  didButton: {
    display: 'inline'
  },
  spinner: {
    paddingTop: tokens.spacingVerticalXXL
  }
});
const openDID = (organizationArn: string, resourceGroup: string, traceparent: string | null) => {
  const searchParams = new URLSearchParams();
  searchParams.set('organizationArn', organizationArn);
  searchParams.set('resourceGroup', resourceGroup);
  if (traceparent) {
    searchParams.set('traceparent', traceparent);
  }
  window.open('/superset?' + searchParams.toString(), '_blank');
};

const Reporting = () => {
  const styles = useStyles();
  const {organization, loaded} = usePopulatedTopbarValues();
  const openTelemetry = useOpenTelemetry();
  const [devices, setDevices] = useState<DeviceInfo[]>();
  const [selectedSerial, setSelectedSerial] = useState<string>('');
  const [panelOpen, setPanelOpen] = useState<boolean>(false);
  const {selectedResourceGroup} = useAppSelector(state => state.resourceGroupsSlice);
  const [searchParams] = useSearchParams();
  const {dispatchAppToast} = useToasts();

  useEffect(() => {
    setDevices(undefined);
    if (!organization?.arn) {
      return;
    }
    getDeviceInfos({organizationArn: organization.arn}, openTelemetry)
      .then(rsp => {
        if ('error' in rsp) {
          throw new Error(rsp.error);
        }
        setDevices(rsp);
        return rsp;
      })
      .then(rsp => {
        rsp.forEach(device => {
          getBasicDeviceInfo(organization.arn, device.serial, openTelemetry).then(info => {
            setDevices(devices =>
              (devices || []).map(d => {
                if (d.serial === device.serial) {
                  // Sanity check because we expect the status to be set only by this callback and
                  // only once.
                  if (d.status !== undefined) {
                    console.error('Expected status be undefined, but found', d.status);
                  }
                  const status =
                    info.status === DeviceStatus.Reachable && d.iotCoreConnected
                      ? DeviceStatus.Connected
                      : info.status;
                  const model = d.model ? d.model : info.model;
                  return {...d, status, model};
                }
                return d;
              })
            );
          });
        });
      })
      .catch(() =>
        dispatchAppToast({
          title: 'Error',
          intent: 'error',
          message: 'Failed to get list of devices'
        })
      );
  }, [organization?.arn, openTelemetry, dispatchAppToast]);

  const selectedDevice = devices?.find(d => d.serial === selectedSerial);

  if (!organization) {
    return <NoValidOrganization loaded={loaded} />;
  }

  return (
    <PageContent data-testid="devices-tab" showResourceGroupTree>
      <PageHeader title={selectedResourceGroup.name} />
      {devices === undefined ? (
        <Spinner className={styles.spinner} size="large" />
      ) : (
        <div>
          <Button
            className={styles.didButton}
            data-testid="open-did-button"
            onClick={() =>
              openDID(organization.arn, selectedResourceGroup.id, searchParams.get('traceparent'))
            }
            appearance="primary"
          >
            Open dashboard
          </Button>
          <DeviceTable
            devices={devices.filter(
              device =>
                selectedResourceGroup.id === '' || device.arn.includes(selectedResourceGroup.id)
            )}
            onDeviceSelected={serial => {
              setSelectedSerial(serial);
              setPanelOpen(true);
            }}
          />
          <DataSourcePanel
            device={selectedDevice}
            panelOpen={panelOpen}
            onClose={() => setPanelOpen(false)}
          />
        </div>
      )}
    </PageContent>
  );
};

export default Reporting;
