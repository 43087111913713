import React, {PropsWithChildren} from 'react';

import {
  AddUserProfileDark,
  AddUserProfileLight,
  DataDark,
  DataLight,
  DevicesDark,
  DevicesLight,
  EmptyFolderDark,
  EmptyFolderLight,
  EmptyGeneralDark,
  EmptyGeneralLight,
  EmptySpaceDark,
  EmptySpaceLight,
  FileMissingDark,
  FileMissingLight,
  NoAccessDark,
  NoAccessLight,
  NoConnectionDark,
  NoConnectionLight,
  NoMatchDark,
  NoMatchLight,
  TeamDark,
  TeamLight,
  bundleIllustrationSmart
} from '@axiscommunications/fluent-illustrations';
import {FluentIllustration} from '@axiscommunications/fluent-illustrations/lib/utils/types';
import {
  Body1,
  Body2,
  Subtitle2,
  Title3,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens
} from '@fluentui/react-components';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    ...shorthands.margin('auto'),
    height: '100%',
    maxWidth: '500px'
  },
  spacer: {
    height: '20%'
  },
  icon: {
    flex: 'none',
    height: '160px',
    width: '230px',
    marginBottom: tokens.spacingVerticalS
  },
  iconMedium: {
    height: '140px',
    width: '200px'
  },
  iconSmall: {
    height: '80px',
    width: '115px'
  },
  title: {
    color: tokens.colorNeutralForeground2,
    textAlign: 'center',
    marginBottom: tokens.spacingVerticalS
  },
  text: {
    color: tokens.colorNeutralForeground2,
    textAlign: 'center',
    marginBottom: tokens.spacingVerticalL
  },
  after: {
    verticalAlign: 'middle',
    marginLeft: tokens.spacingHorizontalXS
  }
});

interface EmptyViewProps {
  readonly title: string;
  readonly after?: JSX.Element;
  readonly size?: 'small' | 'medium' | 'large';
}
function emptyView(
  dark: FluentIllustration = EmptyGeneralDark,
  light: FluentIllustration = EmptyGeneralLight
) {
  return function ({title, children, after, size = 'large'}: PropsWithChildren<EmptyViewProps>) {
    const Icon = bundleIllustrationSmart(dark, light);
    const screenStyles = useStyles();
    const iconStyles = mergeClasses(
      screenStyles.icon,
      size === 'small' && screenStyles.iconSmall,
      size === 'medium' && screenStyles.iconMedium
    );
    const Title = size === 'large' ? Title3 : Subtitle2;
    const Body = size === 'large' ? Body2 : Body1;

    return (
      <div className={screenStyles.container}>
        <div className={screenStyles.spacer} />
        <Icon className={iconStyles} />
        <Title className={screenStyles.title}>{title}</Title>
        <Body className={screenStyles.text}>{children}</Body>
        <div className={screenStyles.after}>{after}</div>
      </div>
    );
  };
}

export const EmptyView = {
  AddUserProfile: emptyView(AddUserProfileDark, AddUserProfileLight),
  Data: emptyView(DataDark, DataLight),
  Devices: emptyView(DevicesDark, DevicesLight),
  EmptyFolder: emptyView(EmptyFolderDark, EmptyFolderLight),
  EmptySpace: emptyView(EmptySpaceDark, EmptySpaceLight),
  FileMissing: emptyView(FileMissingDark, FileMissingLight),
  General: emptyView(EmptyGeneralDark, EmptyGeneralLight),
  NoAccess: emptyView(NoAccessDark, NoAccessLight),
  NoConnection: emptyView(NoConnectionDark, NoConnectionLight),
  NoMatch: emptyView(NoMatchDark, NoMatchLight),
  Team: emptyView(TeamDark, TeamLight)
} as const;
