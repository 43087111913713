import {
  AccordionPanel,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  makeStyles,
  Spinner,
  Switch,
  DataGridBody,
  tokens,
  DataGridCell,
  createTableColumn,
  Text,
  TableColumnDefinition,
  InfoLabel
} from '@fluentui/react-components';
import {
  ACAPStatus,
  ActionDisplayName,
  AoAScenarioInfo,
  DeviceInfo,
  DeviceStatus,
  ScenarioType,
  Tag
} from '../../types';
import useToasts from '../../hooks/useToasts';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useOpenTelemetry} from '@axteams-one/opentelemetry-js-react';
import {ScenarioTagPicker} from '../../components/ScenarioTagPicker';
import {assignTag, enableReporting, getScenarioInfos, unassignTag} from '../../fetcher';
import {useEffect, useState} from 'react';
import {EmptyView} from '../../components/EmptyView';

const useStyles = makeStyles({
  columnText: {
    color: tokens.colorNeutralForeground3
  },
  spinner: {
    justifyContent: 'center',
    paddingTop: tokens.spacingHorizontalM
  },
  dataGrid: {
    paddingTop: tokens.spacingHorizontalL
  },
  dataGridRow: {
    ':active': {
      backgroundColor: 'none'
    }
  }
});

const SCENARIO_TYPES = [
  {type: ScenarioType.CROSSLINE_COUNTING, name: ActionDisplayName.crosslinecounting},
  {type: ScenarioType.OCCUPANCY_IN_AREA, name: ActionDisplayName.occupancyInArea}
];

interface AOAPanelProps {
  readonly device: DeviceInfo;
  readonly tags: Tag[];
  readonly enqueueTagRequest: (promise: () => Promise<void>) => void;
}

const AOAPanel = ({device, tags, enqueueTagRequest}: AOAPanelProps) => {
  const styles = useStyles();
  const {organization} = usePopulatedTopbarValues();
  const openTelemetry = useOpenTelemetry();
  const {dispatchAppToast} = useToasts();

  const [scenarios, setScenarios] = useState<AoAScenarioInfo[]>();
  const [loadingScenarioId, setLoadingScenarioId] = useState<number>();

  useEffect(() => {
    setScenarios(undefined);
    if (
      !organization?.id ||
      !device?.serial ||
      device.status === undefined ||
      device.status === DeviceStatus.Forbidden ||
      device.status === DeviceStatus.Unreachable ||
      device.aoaStatus !== ACAPStatus.Running
    ) {
      return;
    }
    getScenarioInfos({serial: device.serial, organizationId: organization.id}, openTelemetry).then(
      rsp => {
        if ('error' in rsp) {
          dispatchAppToast({
            title: 'Error',
            intent: 'error',
            message: 'Failed to get list of scenarios'
          });
          return;
        }
        setScenarios(rsp);
      }
    );
  }, [device, openTelemetry, organization?.id, dispatchAppToast]);

  if (
    !organization ||
    (scenarios === undefined &&
      device.aoaStatus === ACAPStatus.Running &&
      device.status === DeviceStatus.Reachable)
  ) {
    return <Spinner className={styles.spinner} />;
  }

  const columns: TableColumnDefinition<AoAScenarioInfo>[] = [
    createTableColumn<AoAScenarioInfo>({
      renderHeaderCell: () => <Text className={styles.columnText}>Scenario name</Text>,
      renderCell: item => item.name,
      columnId: 'name'
    }),
    createTableColumn<AoAScenarioInfo>({
      renderHeaderCell: () => (
        <InfoLabel
          info={
            <Text>
              Compatible scenarios are <i>Crossline counting</i> and <i>Occupancy in area</i>.
            </Text>
          }
        >
          <Text className={styles.columnText}>Scenario type</Text>
        </InfoLabel>
      ),
      renderCell: item =>
        SCENARIO_TYPES.find(scenarioType => scenarioType.type === item.type)?.name || item.type,
      columnId: 'type'
    }),
    createTableColumn<AoAScenarioInfo>({
      renderHeaderCell: () => <Text className={styles.columnText}>Tags</Text>,
      renderCell: item => (
        <ScenarioTagPicker
          options={tags
            .filter(tag => !item.tags.includes(tag.name))
            .sort((a, b) => a.name.localeCompare(b.name))}
          selectedOptions={item.tags.sort()}
          onUnassign={tagName => {
            enqueueTagRequest(() =>
              unassignTag(
                {
                  organizationArn: organization.arn,
                  tagName,
                  scenarioId: item.id,
                  serial: device.serial
                },
                openTelemetry
              ).then(success => {
                if (!success) {
                  dispatchAppToast({
                    title: 'Unexpected Error',
                    intent: 'error',
                    message: 'Failed to unassign tag, please reload the page'
                  });
                }
              })
            );
            setScenarios(currentScenarios =>
              currentScenarios?.map(scenario => {
                if (scenario.id === item.id) {
                  return {...scenario, tags: scenario.tags.filter(t => t !== tagName)};
                }
                return scenario;
              })
            );
          }}
          onAssign={tagName => {
            enqueueTagRequest(() =>
              assignTag(
                {
                  organizationArn: organization.arn,
                  tagName,
                  scenarioId: item.id,
                  serial: device.serial
                },
                openTelemetry
              ).then(success => {
                if (!success) {
                  dispatchAppToast({
                    title: 'Unexpected Error',
                    intent: 'error',
                    message: 'Failed to assign tag, please reload the page'
                  });
                }
              })
            );
            setScenarios(currentScenarios =>
              currentScenarios?.map(scenario => {
                if (scenario.id === item.id) {
                  return {...scenario, tags: [...scenario.tags, tagName]};
                }
                return scenario;
              })
            );
          }}
          scenarioId={item.id}
        />
      ),
      columnId: 'tags'
    }),
    createTableColumn<AoAScenarioInfo>({
      renderHeaderCell: () => <Text className={styles.columnText}>Report</Text>,
      renderCell: item =>
        loadingScenarioId === item.id ? (
          <Spinner className={styles.spinner} size="extra-small" />
        ) : (
          <Switch
            data-testid={item.id + '-report-switch'}
            disabled={loadingScenarioId ? true : false}
            checked={item.reportingEnabled}
            onChange={() => {
              if (!organization) {
                return;
              }
              setLoadingScenarioId(item.id);
              const enabledScenarios =
                scenarios
                  ?.filter(scenario => scenario.reportingEnabled && scenario.id !== item.id)
                  .map(scenario => ({
                    id: scenario.id,
                    deviceId: scenario.deviceId
                  })) || [];
              if (!item.reportingEnabled) {
                enabledScenarios.push({id: item.id, deviceId: item.deviceId});
              }
              const payload = {
                organizationId: organization.id,
                devices: [
                  {
                    serial: device.serial,
                    scenarios: enabledScenarios
                  }
                ]
              };
              enableReporting(payload, openTelemetry)
                .then(success => {
                  setLoadingScenarioId(undefined);
                  if (success) {
                    setScenarios(currentScenarios =>
                      currentScenarios?.map(aoaScenario =>
                        aoaScenario.id === item.id
                          ? {...aoaScenario, reportingEnabled: !aoaScenario.reportingEnabled}
                          : aoaScenario
                      )
                    );
                    dispatchAppToast({
                      title: 'Success',
                      intent: 'success',
                      message: `Successfully ${item.reportingEnabled ? 'disabled' : 'enabled'} reporting`
                    });
                  } else {
                    dispatchAppToast({
                      title: 'Error',
                      intent: 'error',
                      message: 'Failed to configure reporting'
                    });
                  }
                })
                .finally(() => setLoadingScenarioId(undefined));
            }}
          />
        ),
      columnId: 'report'
    })
  ];

  return (
    <AccordionPanel>
      {device.aoaStatus === ACAPStatus.Stopped ? (
        <EmptyView.Data title="Analytics is not enabled">
          Start and configure AXIS Object Analytics for the device
        </EmptyView.Data>
      ) : scenarios?.length === 0 ? (
        <EmptyView.NoMatch title="There are no scenarios">
          <InfoLabel
            info={
              <Text>
                <p>Compatible scenarios for Data Insights Dashboard are:</p>
                <ul>
                  <li>Crossline counting</li>
                  <li>Occupancy in area</li>
                </ul>
                <p>
                  <i>Radar cameras are not supported.</i>
                </p>
              </Text>
            }
          >
            Use AXIS Object Analytics to set up compatible scenarios
          </InfoLabel>
        </EmptyView.NoMatch>
      ) : (
        <DataGrid
          className={styles.dataGrid}
          items={scenarios || []}
          columns={columns}
          data-testid="scenario-table"
          columnSizingOptions={{tags: {defaultWidth: 400}}}
          resizableColumns
        >
          <DataGridHeader>
            <DataGridRow>
              {({renderHeaderCell}) => (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody<AoAScenarioInfo>>
            {({item, rowId}) => (
              <DataGridRow<AoAScenarioInfo> className={styles.dataGridRow} key={rowId}>
                {({renderCell}) => <DataGridCell>{renderCell(item)}</DataGridCell>}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>
      )}
    </AccordionPanel>
  );
};

export default AOAPanel;
